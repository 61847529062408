import {initInputEvents} from "./input";
import {SSOResponse} from "./types";
import {translate} from "./translate";
import {handleUser, patchUser} from "./user";
import {app} from "./init";

export const googleLogo = `
    <svg version="1.1" width="18px" height="18px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 533.5 544.3">
        <g>
            <path fill="#4285F4" d="M533.5,278.4c0-18.5-1.5-37.1-4.7-55.3H272.1v104.8h147c-6.1,33.8-25.7,63.7-54.4,82.7v68h87.7 C503.9,431.2,533.5,361.2,533.5,278.4z"/>
            <path fill="#34A853" d="M272.1,544.3c73.4,0,135.3-24.1,180.4-65.7l-87.7-68c-24.4,16.6-55.9,26-92.6,26c-71,0-131.2-47.9-152.8-112.3 H28.9v70.1C75.1,486.3,169.2,544.3,272.1,544.3z"/>
            <path fill="#FBBC04" d="M119.3,324.3c-11.4-33.8-11.4-70.4,0-104.2V150H28.9c-38.6,76.9-38.6,167.5,0,244.4L119.3,324.3z"/>
            <path fill="#EA4335" d="M272.1,107.7c38.8-0.6,76.3,14,104.4,40.8l0,0l77.7-77.7C405,24.6,339.7-0.8,272.1,0C169.2,0,75.1,58,28.9,150 l90.4,70.1C140.8,155.6,201.1,107.7,272.1,107.7z"/>
        </g>
    </svg>
`;

export const bankIDLogo = `
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 538.6 150.2">
        <switch>
            <g>
                <path fill="#4a78b1" d="M0 0h538.6v150.2H0z"/>
                <path fill="#fff"
                      d="M119.5 61.2H94.4c-.8 0-1.4.6-1.4 1.4v6.7c0 .8.6 1.4 1.4 1.4h25.1c.8 0 1.4-.6 1.4-1.4v-6.7c.1-.8-.6-1.4-1.4-1.4zM119.5 79.5H94.4c-.8 0-1.4.6-1.4 1.4v6.7c0 .8.6 1.4 1.4 1.4h25.1c.8 0 1.4-.6 1.4-1.4v-6.7c.1-.7-.6-1.4-1.4-1.4zM156.3 97.9h-25.1c-.8 0-1.4.6-1.4 1.4v6.7c0 .8.6 1.4 1.4 1.4h25.1c.8 0 1.4-.6 1.4-1.4v-6.7c0-.8-.6-1.4-1.4-1.4zM82.8 97.9H57.7c-.8 0-1.4.6-1.4 1.4v6.7c0 .8.6 1.4 1.4 1.4h25.1c.8 0 1.4-.6 1.4-1.4v-6.7c0-.8-.6-1.4-1.4-1.4zM156.3 61.2h-25.1c-.8 0-1.4.6-1.4 1.4v6.7c0 .8.6 1.4 1.4 1.4h25.1c.8 0 1.4-.6 1.4-1.4v-6.7c0-.8-.6-1.4-1.4-1.4zM82.8 79.5H57.7c-.8 0-1.4.6-1.4 1.4v6.7c0 .8.6 1.4 1.4 1.4h25.1c.8 0 1.4-.6 1.4-1.4v-6.7c0-.7-.6-1.4-1.4-1.4zM82.8 42.8H57.7c-.8 0-1.4.6-1.4 1.4v6.7c0 .8.6 1.4 1.4 1.4h25.1c.8 0 1.4-.6 1.4-1.4v-6.7c0-.8-.6-1.4-1.4-1.4zM156.3 42.8h-25.1c-.8 0-1.4.6-1.4 1.4v6.7c0 .8.6 1.4 1.4 1.4h25.1c.8 0 1.4-.6 1.4-1.4v-6.7c0-.8-.6-1.4-1.4-1.4z"/>
                <g>
                    <path fill="#fff" d="M323.4 61.3h-28.7c-.7 0-1.3.6-1.3 1.3v43.2c0 .7.6 1.3 1.3 1.3h6.5c.7 0 1.3-.6 1.3-1.3V71.1c0-.4.3-.6.7-.6h20.2c1.9 0 3.4.6 4.8 2 1.3 1.3 2 2.9 2 4.7v28.7c0 .7.6 1.3 1.3 1.3h6.5c.7 0 1.3-.6 1.3-1.3V77.2c0-4.4-1.6-8.1-4.7-11.2-3-3.1-6.8-4.7-11.2-4.7zM279.3 66c3.1 3.1 4.7 6.9 4.7 11.2v27.4c0 1.4-1.2 2.6-2.7 2.6h-29.2c-7.7 0-14-6.3-14-14s6.3-13.5 14-13.5h22c.4 0 .7-.3.7-.6v-1.9c0-1.8-.7-3.4-2-4.7-1.3-1.3-2.9-2-4.7-2h-23.8c-.7 0-1.3-.6-1.3-1.3v-6.5c0-.7.6-1.3 1.3-1.3h23.8c4.4-.1 8.1 1.5 11.2 4.6zm-4.5 23.6c0-.4-.3-.7-.7-.7h-22.3c-2.6 0-4.7 2.1-4.6 4.7.1 2.5 2.3 4.5 4.8 4.5h22.1c.4 0 .7-.3.7-.6v-7.9zM417.5 43H411c-.7 0-1.3.6-1.3 1.3v61.6c0 .7.6 1.3 1.3 1.3h6.5c.7 0 1.3-.6 1.3-1.3V44.3c.1-.7-.5-1.3-1.3-1.3zm64.8 25.4v13.4c0 16.4-9 25.4-25.3 25.4h-25.8c-.7 0-1.3-.6-1.3-1.3V44.3c0-.7.6-1.3 1.3-1.3h25.6c16.2 0 25.5 9.2 25.5 25.4zm-9.4-.7c0-10.1-5.6-15.6-15.6-15.6h-17.6c-.4 0-.7.3-.7.7v44.6c0 .4.3.7.7.7h17.6c10.2 0 15.6-5.4 15.6-15.5V67.7zm-246-1.7c3.1 3.1 4.7 6.9 4.7 11.2v14.1c0 4.4-1.6 8.2-4.7 11.3-3.1 3.1-6.9 4.6-11.2 4.6H187c-.7 0-1.3-.6-1.3-1.3V44.3c0-.7.6-1.3 1.3-1.3h6.5c.7 0 1.3.6 1.3 1.3v16.3c0 .4.3.7.7.7h20.2c4.3 0 8.1 1.6 11.2 4.7zm-4.5 11.2c0-1.9-.6-3.4-2-4.7-1.3-1.3-2.9-2-4.8-2h-20.1c-.4 0-.7.3-.7.6v26.4c0 .4.3.6.7.6h20.1c1.9 0 3.4-.6 4.8-2 1.3-1.3 2-2.9 2-4.7V77.2zM359.2 80.7c-.2.2-.5.3-.8.2-.3-.1-.4-.4-.5-.7V44.3c0-.7-.6-1.3-1.3-1.3h-6.5c-.7 0-1.3.6-1.3 1.3v61.6c0 .7.6 1.3 1.3 1.3h6.5c.7 0 1.3-.6 1.3-1.3V89c0-.3.2-.5.5-.6.3-.1.6 0 .8.2l21.3 18.4c.3.2.6.3.9.3h8.9c.7 0 1.3-.6 1.3-1.3 0-.4-.2-.7-.4-1l-23-19.8c-.1-.1-.2-.3-.2-.5s.1-.4.2-.5l23.6-20.4c.3-.2.4-.6.4-1 0-.7-.6-1.3-1.3-1.3H382c-.3 0-.7.1-.9.3l-21.9 18.9z"/>
                </g>
            </g>
        </switch>
    </svg>
`;

export const googleButton = `
    <span>${googleLogo}</span>
    <span>${translate.w('Google')}</span>
`;

export function  fillExtraUserInfo(targetID: string, ssoResponse: SSOResponse) {
    const user = ssoResponse.data;
    const phone = `
        <div class="input-wrapper">
            <input id="phone" type="tel" name="phone" value="${user.phone || ''}" autocomplete="tel" />
            <label class="input-label" for="phone">${translate.w('Telefon')}</label>
        </div>
    `;
    const html = `
                <form id="extra-info">
                    <div class="input-wrapper">
                        <input id="email" type="email" name="email" value="${user.email || ''}" autocomplete="username" required autofocus />
                        <label class="input-label" for="email">${translate.w('E-post')}</label>
                    </div>
                    <div class="input-group">
                        <div class="input-wrapper">
                            <input id="firstName" name="firstName" value="" autocomplete="given-name" required />
                            <label class="input-label" id="firstName-label" for="firstName">${translate.w('Fornavn')}</label>
                        </div>
                        <div class="input-wrapper">
                            <input id="lastName" name="lastName" value="" autocomplete="family-name" required />
                            <label class="input-label" id="lastName-label" for="lastName">${translate.w('Etternavn')}</label>
                        </div>
                    </div>
                    ${app.fields.includes('phone') ? phone : ''}

                    <p>${translate.t('Du har logget deg inn. Men vi mangler litt informasjon om deg for å fortsette. Vennligst fyll ut feltene over.')}</p>

                    <div class="button-wrapper flex-end">
                        <button id="update" aria-label="${translate.w('Fortsett')}">${translate.w('Fortsett')}</button>
                    </div>
                </form>
            `;
    const contentTarget = document.getElementById(targetID);
    if (contentTarget) {
        contentTarget.innerHTML = html;
    }
    const extraTarget = document.getElementById('extra-info');
    if (extraTarget) {
        extraTarget.addEventListener('submit', async e => {
            e.preventDefault();
            await patchUser(ssoResponse.data, ssoResponse.accessToken.token);
            await handleUser(ssoResponse);
        });
    }
    initInputEvents();
}

export function busyHandler(errorWrapper: string = 'error', buttonID: string = 'submit') {
    const button = document.getElementById(buttonID)! as HTMLButtonElement;
    const wrapper = document.getElementById(errorWrapper);

    setBusy(true)
    if (button) {
        button.disabled = true;
    }
    if (wrapper) {
        wrapper.innerHTML = "";
    }
    return {
        onError: (text: string) => {
            setBusy(false);
            if (button) {
                button.disabled = false;
            }
            if (wrapper) {
                wrapper.innerHTML = text;
            }
        },
        onSuccess: (disabled = false, buttonText?: string) => {
            setBusy(false)
            if (button) {
                button.disabled = disabled;
                if (buttonText) {
                    button.innerText = buttonText;
                    button.style.animation = 'none';
                    button.style.cursor = 'disabled';
                }
            }
        }
    }
}

export function setBusy(busy: boolean) {
    const loader = `
        <div class="busy-loader" />
    `;

    const element = document.getElementById('busy');
    if (busy && element) {
        element.innerHTML = loader;
    } else if(element) {
        element.innerHTML = '';
    }
}